
import React, { useEffect, useRef } from "react";
import AppRoutes from "./Routes/AppRoutes";
import SocketNotification from "./socket/SocketNotification";
import { useColorScheme } from "./store/colorSchemeStore";
import { Toaster } from "react-hot-toast";
import IdleTimeoutHandler from "./components/Common/IdleTimeoutHandler";
import EventListModal from "./components/Common/EventListModal";

const App = () => {

  const color = useColorScheme((state)=> state.bgColor)
  const activeTextColor = useColorScheme((state)=> state.activeTextColor)
  const assignBgSelected = useColorScheme((state)=> state.assignBgSelected)
  const assignBgNonSelected = useColorScheme((state)=> state.assignBgNonSelected)
  const fontFamily = useColorScheme((state)=> state.fontFamily)

  useEffect(() => {
      document.documentElement.style.setProperty('--primary-color', color);
      document.documentElement.style.setProperty('--active-sidebar', activeTextColor);
      document.documentElement.style.setProperty('--assign-bg-selected', assignBgSelected);
      document.documentElement.style.setProperty('--assign-bg-non-selected', assignBgNonSelected);
      document.documentElement.style.setProperty('--primary-font', fontFamily)
      document.documentElement.style.setProperty('--primary-font', 'Poppins')
  }, []);

  return (
    <div>
      <SocketNotification />
      <AppRoutes />
      <IdleTimeoutHandler />
      <EventListModal />
      <Toaster
      position="top-center"
      reverseOrder={false}
      />
    </div>
  );
}

export default App;
